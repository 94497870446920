import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css'; // Import core Swiper styles
import 'swiper/css/navigation'; // Import navigation module styles
import 'swiper/css/pagination'; // Import pagination module styles


// Images
import img from '../images/web.svg'; 
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';
import img5 from '../images/consultation.svg'; // Add your new image

const Services = () => {

    const serviceData = [
        {
            img: img,
            title: "Cloud Native App Development",
            description: "We specialize in developing and modernizing web and mobile applications that are cloud-native. Our services include designing scalable, resilient applications that fully leverage the cloud's capabilities to meet modern business need."
        },
        {
            img: img2,
            title: "Database & Analytics",
            description: "Our expertise covers database operations, data lakes, ETL pipelines, and advanced analytics. We offer predictive analytics and visualization services to help you turn data into actionable insights for smarter decision-making."
        },
        {
            img: img3,
            title: "Cloud Security Services",
            description: "We offer thorough assessments, strategic advice, and implementation of cloud security measures. Our services cover automation and continuous operation to protect your cloud environment from evolving threats"
        },
        {
            img: img4,
            title: "DevOps & Infra",
            description: "We provide comprehensive DevOps services, including CI/CD, infrastructure as code, and containerization. Our team ensures efficient capacity planning to keep your applications running smoothly and scaling as needed"
        },
        {
            img: img5,
            title: "Managed Services",
            description: "Our managed services include real-time monitoring, technical support, and well-architected reviews. We ensure your cloud infrastructure is always optimized and running at peak efficiency, with expert support available round the clock."
        },
        {
            img: img5,
            title: "Cloud Migration",
            description: "We offer end-to-end cloud migration services, including architecture review and the migration of data and applications. Our team ensures a smooth transition to the cloud, minimizing downtime and ensuring continuity"
        }
    ];

    return (
        <div id="services" className="bg-gray-100 py-12">
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Services</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
                        We are deeply committed to the growth and success of our clients.
                    </h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={1}
                        breakpoints={{
                            640: { slidesPerView: 2 },
                            1024: { slidesPerView: 4 },
                        }}
                        navigation={true}
                        pagination={true}
                        modules={[Navigation, Pagination]} 
                    >
                        {serviceData.map((service, index) => (
                            <SwiperSlide key={index}>
                                <div className= " card bg-white transition-all h-[30rem] ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group card">
                                    <div className="m-2 text-justify text-sm">
                                        <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={service.img} />
                                        <h2 className="font-semibold my-4 text-2xl text-center">{service.title}</h2>
                                        <p className="text-md font-medium">
                                            {service.description}
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'>
                                    <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                                    <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
                                </svg>
                            </div>
                            <h3 className="text-3xl text-blue-900 font-bold">
                                We <span className='font-black'>Build</span>
                            </h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                With over 25 years experience in software analysis and design and a deep understanding of the latest IT trends and solutions, we provide customized recommendations and strategies to help you improve your operations, reduce costs, and increase efficiency.

</p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'>
                                    <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                                </svg>
                            </div>
                            <h3 className="text-3xl text-blue-900 font-bold">
                                We <span className='font-black'>Collaborate</span>
                            </h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                    We can collaborate with your existing tech team to scale existing software applications or design customized software applications that suit your everyday needs and simplify various processes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


             <style jsx>{`
        .swiper-button-next, .swiper-button-prev {
          color: blue;
          background-color: rgba(255, 255, 255, 0.8);
          border-radius: 50%;
          padding: 10px;
          width: 50px;
          height: 50px;
          top: 50%;
          transform: translateY(-50%);
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        }
        .swiper-button-next:hover, .swiper-button-prev:hover {
          background-color: rgb(30, 58, 138)
;
          color: white;
        }
        .swiper-button-next::after, .swiper-button-prev::after {
          font-size: 20px;
          font-weight: bold;
        }
.swiper-pagination{
display: none;
}
        @media (max-width: 1024px) {
                    .swiper-button-next, .swiper-button-prev {
                        display: none;
                    }
                       
                    
                }

             }
      `}</style>
        </div>
    );
};

export default Services;
