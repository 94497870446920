import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules'; // Import Autoplay and Pagination modules
import 'swiper/css'; 
import 'swiper/css/pagination';

// Client Images Import
import freecharge from '../images/clients/freecharge-app-30-1464612910-15-1473945001-removebg-preview.png';
import noice from '../images/clients/b70d5e72879fe5781b3cea722071edd7.png';
import eyewa from '../images/clients/images__1_-removebg-preview.png';
import cardekho from '../images/clients/GeneralNews.webp';
import anscommerce from '../images/clients/images-removebg-preview.png';
import cleartrip from "../images/clients/cleartrip.png";
import rupyy from "../images/clients/rupyy.png";
import flyin from "../images/clients/flyin.gif";
import aqua from "../images/clients/logo.jpg";
import Headsup from "../images/clients/heads up.jpg";
import cynergy from "../images/clients/cynergy.png";

// Custom inline styles
const clientImageStyle = {
  height: '10rem', 
  width: 'auto',
  mixBlendMode: 'color-burn', 
};

const Clients = () => {
  const clientLogos = [
    freecharge, noice, eyewa, cardekho, anscommerce, cleartrip, rupyy, flyin, aqua, Headsup, cynergy
  ];

  return (
    <div className="mt-8 bg-gray-100">
      <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Clients</h2>
          <div className='flex justify-center'>
            <div className='w-24 border-b-4 border-blue-900'></div>
          </div>
          
        </div>

        <div className="p-16 relative">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            breakpoints={{
              640: { slidesPerView: 2 },
              1024: { slidesPerView: 5 },
            }}
            autoplay={{ delay: 1000, disableOnInteraction: false }} // Set autoplay delay to 1000ms
            pagination={{ clickable: true, el: '.swiper-pagination' }} // Ensure pagination is clickable and target custom pagination container
            modules={[Autoplay, Pagination]} // Include Autoplay and Pagination modules
          >
            {clientLogos.map((logo, index) => (
              <SwiperSlide key={index}>
                <div className="p-3 flex justify-center transition-all ease-in-out">
                  <img 
                    src={logo} 
                    alt={`client-${index}`} 
                    style={clientImageStyle}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="swiper-pagination" /> {/* Custom pagination container */}
        </div>
      </section>

      <style jsx>{`
        .swiper-pagination {
          position: absolute;
          bottom: -50px; /* Adjust this value to move pagination below the Swiper */
          left: 0;
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .swiper-pagination-bullet {
          background-color: blue;
        }
        .swiper-pagination-bullet-active {
          background-color: darkblue;
        }
      `}</style>
    </div>
  );
};

export default Clients;
