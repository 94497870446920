import React from 'react';
import { 
  Groups, 
  Cloud, 
  SupportAgent,
  Security, 
  Groups2
} from '@mui/icons-material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Features = () => {
    const items = [
        {
            icon: <Groups fontSize="large" />,
            title: 'Diverse Technical Team',
            description: 'Our diverse team of experts brings a wide range of skills and experience, ensuring comprehensive solutions tailored to your needs.'
        },
        {
            icon: <Groups2 fontSize="large" />,
            title: '30+ Customers',
            description: 'We have successfully delivered solutions to over 30 customers, helping them achieve their goals with our innovative technologies.'
        },
        {
            icon: <Cloud fontSize="large" />,
            title: '50+ Cloud Projects',
            description: 'Our extensive experience includes managing over 50 cloud projects, optimizing performance and scalability for various applications.'
        },
        {
            icon: <SupportAgent fontSize="large" />,
            title: 'Supporting Customers Globally',
            description: 'We provide support to customers across the globe, ensuring reliable and efficient service no matter where you are located.'
        },
        {
            icon: <Security fontSize="large" />,
            title: 'AWS Security Partner',
            description: 'As an AWS Security Partner, we are committed to delivering secure and compliant solutions, leveraging AWS’s robust security framework.'
        }
    ];

    // Slider settings for react-slick
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1, // Show one slide at a time on mobile
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // Tablet and up
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 768, // Mobile devices
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    return (
        <div className="my-4 py-4" id='features'>
            <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
                What makes us different
            </h2>
            <div className='flex justify-center'>
                <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
            </div>

            <div className="px-4 sm:px-10">
                {/* Desktop and Tablet View */}
                <div className="hidden md:flex  gap-6  " >
                    {items.map((item, index) => (
                        <div 
                            key={index} 
                            className="bg-white transition-transform transform hover:scale-105 hover:bg-blue-900 hover:text-white rounded-lg shadow-md p-6 max-w-xs mx-auto" 
                        >
                            <div className="flex items-center justify-center mb-4">
                                <div className="text-blue-900 transition-colors duration-300 group-hover:text-white"> 
                                    {item.icon}
                                </div>
                            </div>
                            <h4 className="font-semibold text-lg text-center mb-2">{item.title}</h4>
                            <p className="text-md font-medium leading-5">
                                {item.description}
                            </p>
                        </div>
                    ))}
                </div>

                {/* Mobile View with Slider */}
                <div className="md:hidden">
                    <Slider {...sliderSettings}>
                        {items.map((item, index) => (
                            <div key={index} className="px-2">
                                <div 
                                    className="bg-white transition-transform transform hover:scale-105 hover:bg-blue-900 hover:text-white rounded-lg shadow-md p-6 max-w-xs mx-auto" 
                                >
                                    <div className="flex items-center justify-center mb-4">
                                        <div className="text-blue-900 transition-colors duration-300 group-hover:text-white"> 
                                            {item.icon}
                                        </div>
                                    </div>
                                    <h4 className="font-semibold text-lg text-center mb-2">{item.title}</h4>
                                    <p className="text-md font-medium leading-5">
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default Features;
